import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ScreensRoot from './screens/Root'
// import Loading from './components/UI/Loading'
// import Alert from './components/UI/Alert/Alert'
// import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <>
    {/* <Loading />

		<Alert /> */}
		
		<div className="container-master">
			<ScreensRoot />
		</div>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
