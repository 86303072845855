import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Menu } from '@material-ui/icons'
import './style.css'

export default class NavBar extends Component {
    constructor() {
        super()

        this.state = {
            menuMobile: false
        }
    }

    openMenu() {
        this.setState({
            menuMobile: !this.state.menuMobile
        })
    }

    closeMenu() {
        this.setState({
            menuMobile: false
        })
    }

    render() {
        return (
            <header>
                <nav className="contentTabs">
                    <NavLink className="Tabs logo" to={`/home`} onClick={this.closeMenu.bind(this)}>
                        <img src="https://wappa-assets.s3.amazonaws.com/logo-wappa-2021/logo-svg/logowappa.svg" className="logotipo" alt="Wappa"/>
                        {/* <img src="https://wappa-assets.s3.amazonaws.com/logo-wappa-2021/logo-svg/logowappa-2.svg" className="logo-only" alt="Wappa"/> */}
                    </NavLink>
                    <div className="menu-mobile" onClick={this.openMenu.bind(this)}>
                        <Menu></Menu>
                    </div>
                    <div className={this.state.menuMobile ? `menu active` : `menu` }>
                        <div className="Tabs Menu" to={`/para-empresas`}>
                            <span>Para sua empresa</span>

                            <div className="sub-menu">
                                <NavLink className="Tabs" to={`/logistica`} onClick={this.closeMenu.bind(this)}><span>Entregadores para sua empresa</span></NavLink>
                                <NavLink className="Tabs" to={`/mobilidade`} onClick={this.closeMenu.bind(this)}><span>Motoristas para seu time</span></NavLink>
                            </div>
                        </div>
                        <NavLink className="Tabs" to={`/motoristas-entregadores`} onClick={this.closeMenu.bind(this)}><span>Motoristas / Entregadores</span></NavLink>
                        <NavLink className="Tabs" to={`/para-voce`} onClick={this.closeMenu.bind(this)}><span>Para Você</span></NavLink>
                        <NavLink className="Tabs" to={`/quem-somos`} onClick={this.closeMenu.bind(this)}><span>Quem Somos</span></NavLink>
                        <a className="Tabs login" href="https://login.wappa.com.br/"><span>Login</span></a>
                    </div>
                </nav>
                {/* Magic : */}
                {this.props.children}   
            </header>
        );
      }
}