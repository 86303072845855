import React , { Component } from 'react'
import Button from '../UI/Button/Button'
import { InputTextVal } from '../UI/Input/Input'
import { PRE_CADASTRO } from '../../constants/ApiConstants'
// import PubSub from 'pubsub-js'
// import axios from 'axios'
import './style.css'
import Axios from 'axios'

export default class EmployeeRegister extends Component {
    constructor() {
        super()

        this.state = {
            token: null,
            step: 1,
            email: '',
            emailError: '',
            name: '',
            registration: '',
            phone: '',

        }
    }

    handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox'

        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    setError = (type, valid) => {
        console.log(type, valid)
        this.setState({
            [type]: valid
        })
    }

    componentDidMount() {
        this.validate()
    }

    validate() {
        const req = {
            Token: this.props.token
        }

        let stepResult = null

        Axios.post(`${PRE_CADASTRO}validar`, req).then(resp => {
            if(resp.data.Sucesso) {
                stepResult = 3
            } else {
                stepResult = 1   
            }

            this.setState({
                step: stepResult,
                email: resp.data.Email
            })
        })
    }

    checkEmail(e) {
        e.preventDefault()

        const req = {
            Email: this.state.email
        }

        Axios.post(`${PRE_CADASTRO}gerar`, req).then(resp => {
            console.log(resp.data)

            if(!resp.data.Sucesso) {
                this.setState({
                    emailError: resp.data.Erro.Message
                })
            } else {
                this.setState({
                    step: 2,
                })
            }
        }).catch(error => {
            console.log(error.response)
        })
    }

    registerEmployee(e) {
        e.preventDefault()

        const req = {
            Nome: this.state.name,
            Matricula: this.state.registration,
            Email: this.state.email,
            DDD: this.state.phone.slice(0, 2),
            Telefone: this.state.phone.slice(2, 11),
            Cargo: 1,
            Token: this.props.token
        }

        console.log(req)

        Axios.post(`${PRE_CADASTRO}cadastrar`, req).then(resp => {
            this.setState({
                step: 4,
            })
        }).catch(error => {
            console.log(error.response)
        })
    }

    render() {
        return(
            <div className="employee-register">

                {/* Firt Step */}
                {this.state.step === 1 &&
                    <div className="first-step">
                        <h1>Cadastre seu email para continuar</h1>

                        <form>
                            <div className="row">
                                <InputTextVal
                                    type="email"
                                    autoComplete={'off'}
                                    name="email"
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    error={this.state.emailError}
                                    setError={this.setError.bind(this, 'emailError')}
                                    text="E-mail"
                                ></InputTextVal>
                            </div>
                            <div className="options">
                                <Button
                                    onClick={this.checkEmail.bind(this)}
                                    disabled={
                                        !this.state.email ||
                                        this.state.emailError
                                    }
                                    text="Avançar"
                                ></Button>
                            </div>
                        </form>
                    </div>
                }

                {/* Second Step */}
                {this.state.step === 2 &&
                    <div className="second-step">
                        <h1>Enviamos um email para:</h1>

                        <p>
                            {this.state.email}
                        </p>

                        <p>
                            Verifique sua caixa de entrada.
                        </p>
                    </div>
                }

                {/* Third Step */}
                {this.state.step === 3 &&
                    <div className="fourth-step">
                        <h1>E-mail verificado com sucesso.</h1>

                        <p>
                            Complete seu cadastro
                        </p>

                        <form action="register">
                            <div className="row">
                                <InputTextVal
                                    type="text"
                                    autoComplete={'off'}
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    text="Nome"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal
                                    type="text"
                                    autoComplete={'off'}
                                    name="registration"
                                    onChange={this.handleChange}
                                    value={this.state.registration}
                                    text="Matrícula"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal
                                    type="email"
                                    autoComplete={'off'}
                                    name="email"
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    error={this.state.emailError}
                                    setError={this.setError.bind(this, 'emailError')}
                                    text="E-mail"
                                    disabled="disabled"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal type="number"
                                    text="Celular"
                                    autoComplete="off"
                                    mask="phone"
                                    name="phone"
                                    max="99999999999"
                                    maxLength={10}
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    error={this.state.phoneError}
                                    setError={this.setError.bind(this, 'phoneError')}
                                ></InputTextVal>
                            </div>
                            <div className="options">
                                <Button
                                    onClick={this.registerEmployee.bind(this)}
                                    disabled={
                                        !this.state.email ||
                                        this.state.emailError ||
                                        !this.state.name ||
                                        !this.state.registration ||
                                        !this.state.phone
                                    }
                                    text="Avançar"
                                ></Button>
                            </div>
                        </form>
                    </div>
                }

                {/* fourth Step */}
                {this.state.step === 4 && 
                    <div className="fourth-step">
                        <p><b>{this.state.name}</b></p>

                        <h1>
                            Obrigado por completar o seu cadastro.
                        </h1>

                        <p>
                            Enviamos seus dados de acesso do<br/>
                            Sistema Wappa para <span className="email">{this.state.email}</span>
                        </p>
                    </div>
                }
            </div>
        )
    }
}