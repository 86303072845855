import React , { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

export default class Mobility extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Mobilidade"
        }
    }

    render() {
        return(
            <main className="mobility">
                <section className="div-1">
                    <div className="banner">
                        <h1>Táxi e carro particular</h1>
                        <h2>no mesmo lugar</h2>

                        <p>
                            Reduza os gastos com mobilidade
                            da sua empresa e acompanhe tudo
                            por centros de custos.
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Experimente</NavLink>
                    </div>
                </section>
                <section className="div-2">
                    <div className="foto">
                        <img src="/assets/img/mobility/Imagem-5.jpg" alt="app táxi"/>
                    </div>
                    <div className="text">
                        <h1>Só na Wappa você tem:</h1>

                        <ul>
                            <li>Relatórios detalhados</li>
                            <li>Alerta de utilização conforme política de uso</li>
                            <li>Cobertura Nacional</li>
                            <li>Mais de 200 mil Motoristas cadastrados</li>
                            <li>Categorias de mobilidade acessíveis</li>
                        </ul>

                        <a href="/para-empresas">Quero experimentar</a>
                    </div>
                </section>
                <section className="div-3">
                    <h1>Por que solicitar um carro via Wappa?</h1>

                    <div className="row">
                        <div className="col">
                            <div className="ico">
                                <img src="/assets/img/mobility/ico-1.png" alt="app táxi"/>
                            </div>
                            <p>
                                Centralize todo fluxo de
                                mobilidade da sua empresa,
                                no mesmo lugar!
                            </p>
                        </div>
                        <div className="col">
                            <div className="ico">
                                <img src="/assets/img/mobility/ico-2.png" alt="app táxi"/>
                            </div>
                            <p>
                                Na Wappa você conta com
                                um atendimento premium,
                                viagens seguras e motoristas
                                qualificados.
                            </p>
                        </div>
                        <div className="col">
                            <div className="ico">
                                <img src="/assets/img/mobility/ico-3.png" alt="app táxi"/>
                            </div>
                            <p>
                                Reduza os gastos com
                                Mobilidade. Economize
                                e gerencie tudo dentro
                                da mesma plataforma.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="div-4">
                    <div className="text">
                        <h1>
                            Utilize uma plataforma<br/>
                            completa para gestão
                        </h1>

                        <p>
                            Uma plataforma completa para você controlar e visualizar o fluxo de mobilidade,
                            integrar com sistemas Financeiros e folhas de pagamento e controlar o
                            orçamento por centro de custo e colaboradores.
                        </p>
                    </div>
                    <div className="foto">
                        <img src="/assets/img/mobility/Imagem-3.jpg" alt="app táxi"/>
                    </div>
                </section>
                <section className="div-5">
                    <h1>Nossas Categorias</h1>

                    <div className="content">
                        <div className="foto">
                            <img src="/assets/img/mobility/celular-site-2.png" alt="app táxi"/>
                        </div>

                        <div className="text">
                            <div className="row">
                                <div className="col">
                                    <h2>Táxi</h2>
                                    <p>
                                        Segurança e agilidade.<br/>
                                        Com táxi você pode ir
                                        pelos corredores e chegar
                                        mais rápido onde quiser.
                                    </p>
                                </div>
                                <div className="col">
                                    <h2>Táxi Promo</h2>
                                    <p>
                                        Economia. <br/>
                                        Toda agilidade e
                                        segurança por um
                                        preço especial.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h2>Economy</h2>
                                    <p>
                                        Carro particular
                                        por um preço
                                        econômico sempre
                                        que você precisar.
                                    </p>
                                </div>
                                <div className="col">
                                    <h2>Premium</h2>
                                    <p>
                                        Carro particular de acordo
                                        com a sua necessidade e
                                        conforto.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h2>Mulher</h2>
                                    <p>
                                        Apenas motoristas
                                        mulheres. Excelentes
                                        Motoristas para levar
                                        passageiras, em
                                        segurança.
                                    </p>
                                </div>
                                <div className="col">
                                    <h2>Acessível</h2>
                                    <p>
                                        Carros adaptados
                                        para pessoas com
                                        necessidades
                                        especiais.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="div-6">
                    <h1>Vá de Wappa, em segurança</h1>
                </section>
            </main>
        )
    }
}