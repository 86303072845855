import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

export default class Footer extends Component {
    openApp(store) {
        let storeUrl = null
        switch (store) {
            case 'appstore':
                storeUrl = 'https://apps.apple.com/br/app/wappa-t%C3%A1xi-com-seguran%C3%A7a-e-praticidade/id385335472'
                break;
            case 'googleplay':
                storeUrl = 'https://play.google.com/store/apps/details?id=br.com.wappa.appmobilecolaborador&hl=pt_BR'
                break;
            default:
                break;
        }

        const appWindow = window.open(storeUrl)

        setTimeout(() => {
            if (appWindow) {
                appWindow.location = storeUrl
            }
        },1000);
    }

    render() {
        return (
            <>
                <footer>
                    <NavLink className="logo" to={`/home`}>
                        <img src="https://wappa-assets.s3.amazonaws.com/logo-wappa-2021/logo-svg/logowappa.svg" alt="Wappa"/>
                    </NavLink>

                    <nav className="contentTabs">
                        <NavLink className="Tabs" to={`/para-empresas`}><span>Para sua empresa</span></NavLink>
                        <NavLink className="Tabs" to={`/motoristas-entregadores`}><span>Motoristas / Entregadores</span></NavLink>
                        <NavLink className="Tabs" to={`/para-voce`}><span>Para Você</span></NavLink>
                        <NavLink className="Tabs" to={`/quem-somos`}><span>Quem Somos</span></NavLink>
                        <a className="Tabs login" href="http://login.wappa.com.br/" ><span>Login</span></a>
                    </nav>

                    <div className="social-networks">
                        <p>Siga a Wappa nas redes sociais</p>
                        

                        <div className="list">
                            <a href="https://www.facebook.com/WappaBrasil/" target="blank" rel="noopener noreferrer">
                                <span className="icon-ico-facebook"></span>
                            </a>
                            <a href="https://www.linkedin.com/company/wappa-brasil/" target="blank" rel="noopener noreferrer">
                                <span className="icon-ico-linkedin"></span>
                            </a>
                            <a href="https://www.instagram.com/wappabrasil/" target="blank" rel="noopener noreferrer">
                                <span className="icon-ico-instagram"></span>
                            </a>
                            <a href="https://twitter.com/WappaTaxi" target="blank" rel="noopener noreferrer">
                                <span className="icon-ico-twitter"></span>
                            </a>
                            <a href="https://www.youtube.com/user/wappataxi" target="blank" rel="noopener noreferrer">
                                <span className="icon-ico-youtube"></span>
                            </a>
                        </div>

                        <p>Relações com Imprensa:<br/><a href="mailto:imprensa@wappa.com.br">imprensa@wappa.com.br</a></p>
                    </div>

                    <div className="app-stores">
                        
                        <div onClick={this.openApp.bind(this, 'appstore')} className="apple-store">Apple store</div>


                        <div onClick={this.openApp.bind(this, 'googleplay')} className="google-play">Google Play</div>
                    </div>

                    {/* Magic : */}
                    {this.props.children}   
                </footer>

                <div className="copyrigth">
                    <a href="docs/Termos-Condicoes-Gerais.pdf" target="blank" rel="noopener noreferrer">Termos de uso</a>
                    •
                    <a href="/docs/Politica-Privacidade.pdf" target="blank" rel="noopener noreferrer">Politica de privacidade</a>
                </div>
            </>
        );
      }
}