import React, { Component } from "react";
import Button from "../UI/Button/Button";
import ClientPremiumContact from "./Forms/ClientPremiumContact";
import { ONBOARDING_API } from "../../constants/ApiConstants";
import { InputTextVal } from "../UI/Input/Input";
// import PubSub from 'pubsub-js'
import axios from "axios";
import "./style.css";

export default class ForBusinesses extends Component {
	constructor() {
		super();

		this.state = {
			title: "Para Empresas",
			step: 0,
			resendEmailSuccess: false,
			email: "",
			continueRegister: false,
		};
	}

	componentDidMount() {
		this.getCompany();
	}

	openApp(store) {
		let storeUrl = null;
		switch (store) {
			case "appstore":
				storeUrl =
					"https://apps.apple.com/br/app/wappa-t%C3%A1xi-com-seguran%C3%A7a-e-praticidade/id385335472";
				break;
			case "googleplay":
				storeUrl =
					"https://play.google.com/store/apps/details?id=br.com.wappa.appmobilecolaborador&hl=pt_BR";
				break;
			default:
				break;
		}

		const appWindow = window.open(storeUrl);

		setTimeout(() => {
			if (appWindow) {
				appWindow.location = storeUrl;
			}
		}, 1000);
	}

	getCompany = () => {
		let currentComponent = this;

		const companyEmail = sessionStorage.getItem("companyEmail");

		if (companyEmail) {
			axios.get(`${ONBOARDING_API}${companyEmail}`).then(function (resp) {
				let lastStep = null;

				if (resp.data.company) {
					lastStep = 4;
				}

				if (resp.data.location) {
					lastStep = 5;
				}

				if (resp.data.payment) {
					lastStep = 8;
				}

				currentComponent.setState({
					step: lastStep,
				});
			});
		}
	};

	resendEmail() {
		const companyId = sessionStorage.getItem("companyId");

		axios.post(`${ONBOARDING_API}${companyId}/welcome-mail`).then(() => {
			this.setState({
				resendEmailSuccess: true,
			});
		});
	}

	handleChange = (event) => {
		const isCheckbox = event.target.type === "checkbox";

		this.setState({
			[event.target.name]: isCheckbox
				? event.target.checked
				: event.target.value,
		});
	};

	setError = (type, valid) => {
		console.log(type, valid);
		this.setState({
			[type]: valid,
		});
	};

	stepForward(step) {
		window.scrollTo(0, 0);

		this.setState({
			step: step,
		});
	}

	coninueRegister() {
		let currentComponent = this;

		axios.get(`${ONBOARDING_API}${this.state.email}`).then(function (resp) {
			let lastStep = null;

			sessionStorage.setItem("companyId", resp.data.company.id);

			if (resp.data.company) {
				lastStep = 4;
			}

			if (resp.data.location) {
				lastStep = 5;
			}

			if (resp.data.payment) {
				lastStep = 8;

				window.gtag("event", "conversion", {
					on: "visible",
					vars: {
						event_name: "conversion_express",
						send_to: ["AW-914084232/ElL3CIe7nesBEIij77MD"],
					},
				});
			}

			currentComponent.setState({
				step: lastStep,
			});
		});
	}

	setContinueRegister() {
		this.setState({
			continueRegister: true,
		});
	}

	render() {
		return (
			<div className="container-onboarding-client">
				{this.state.step === 0 && (
					<div className="step-onboarding">
						<h1>Que bom que você chegou!</h1>

						<p>Como você quer usar os serviços da Wappa?</p>

						<div className="options">
							<Button
								onClick={this.stepForward.bind(this, 1)}
								text="Para você"
							></Button>

							<Button
								onClick={this.stepForward.bind(this, 9)}
								text="Para sua empresa"
							></Button>
						</div>

						<p className="msg-continue-register">
							Caso já tenha iniciado um cadastro,{" "}
							<span
								className="coninue-register"
								onClick={this.setContinueRegister.bind(this)}
							>
								clique aqui
							</span>{" "}
							para continuar
						</p>

						{this.state.continueRegister && (
							<div className="form-continue-register">
								<div>
									<InputTextVal
										type="email"
										autoComplete={"off"}
										name="email"
										onChange={this.handleChange}
										value={this.state.email}
										error={this.state.emailError}
										setError={this.setError.bind(this, "emailError")}
										text="E-mail cadastrado"
									></InputTextVal>
								</div>
								<div className="options">
									<Button
										onClick={this.coninueRegister.bind(this, 2)}
										text="Continuar"
										disabled={!this.state.email || this.state.emailError}
									></Button>
								</div>
							</div>
						)}
					</div>
				)}

				{this.state.step === 1 && (
					<div className="step-onboarding">
						<h1>Legal!</h1>

						<p>
							Baixe nosso aplicativo e aproveite as diversas categorias de
							Mobilidade e Entregas em um só app!
						</p>

						<div className="options">
							<div className="app-stores">
								<div
									onClick={this.openApp.bind(this, "appstore")}
									className="apple-store"
								>
									Apple store
								</div>

								<div
									onClick={this.openApp.bind(this, "googleplay")}
									className="google-play"
								>
									Google Play
								</div>
							</div>
						</div>
					</div>
				)}

				{this.state.step === 2 && (
					<div className="step-onboarding step-spend">
						<h1>Conta pra gente</h1>

						<p>
							Qual gasto médio que você tem com Entregas e <br />
							Mobilidade na sua empresa?
						</p>

						<div className="options">
							<Button
								onClick={this.stepForward.bind(this, 3)}
								text="Até 5 mil"
							></Button>

							<Button
								onClick={this.stepForward.bind(this, 9)}
								text="Mais de 5 mil"
							></Button>
						</div>
					</div>
				)}

				{this.state.step === 7 && (
					<div className="step-onboarding">
						<p>Essa opção não está disponível no momento. :(</p>

						<p>
							Fique atento, informaremos você assim que liberarmos a função.
							Caso você queria utilizar os serviços da Wappa agora, escolha a
							opção cartão de crédito.
						</p>

						<h1>Conte com a Wappa!</h1>

						<div className="options">
							<Button
								onClick={this.stepForward.bind(this, 6)}
								text="Cadastar cartão"
							></Button>
						</div>
					</div>
				)}

				{this.state.step === 8 && (
					<div className="step-onboarding">
						<p>Legal</p>

						<p>Você vai receber um e-mail com os próximos passos!</p>

						<h1>Bem-vindo a Wappa!</h1>

						{!this.state.resendEmailSuccess ? (
							<div className="disclaimer">
								Caso já tenha cadastro e queria receber o e-mail de confirmação
								novamente{" "}
								<div className="link" onClick={this.resendEmail.bind(this)}>
									clique aqui!
								</div>
							</div>
						) : (
							<div className="disclaimer">
								E-mail de confrimação foi reenviado. <br />
								confira sua caixa de entrada pra efetuar seu primeiro login.
							</div>
						)}
					</div>
				)}

				{this.state.step === 9 && (
					<div className="step-onboarding">
						<ClientPremiumContact
							sendInfo={this.stepForward.bind(this, 4)}
						></ClientPremiumContact>
					</div>
				)}
			</div>
		);
	}
}
