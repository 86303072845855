import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import NavComponent from '../components/NavBar'
import FooterComponent from '../components/Footer'

import ScreenHome from './Home'
import ScreenForBusinesses from './ForBusinesses'
import ScreenMobility from './Mobility'
import ScreenLogistics from './Logistics'
import ScreenPartners from './Partners'
import ScreenPersonal from './Personal'
import ScreenAboutUs from './AboutUs'
import ScreenContactUs from './ContactUs'
import ScreenEmployeeRegister from './EmployeeRegister'
import ScreenLogin from './Login'
import ScreenIntegracaoexpense from './Integracaoexpense'

export default class ScreensRoot extends Component {
  render() {
    return(
      <BrowserRouter>
        <NavComponent />
        <div className="contaner-content">
          <Switch>
            <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/home" />
                    )
                }}
              />
            <PropsRoute path="/home" component={ScreenHome} />
            <PropsRoute path="/para-empresas" component={ScreenForBusinesses} />
            <PropsRoute path="/mobilidade" component={ScreenMobility} />
            <PropsRoute path="/logistica" component={ScreenLogistics} />
            <PropsRoute path="/motoristas-entregadores" component={ScreenPartners} />
            <PropsRoute path="/para-voce" component={ScreenPersonal} />
            <PropsRoute path="/quem-somos" component={ScreenAboutUs} />
            <PropsRoute path="/fale-conosco" component={ScreenContactUs} />
            <PropsRoute path="/cadastro-colaborador/:token" component={ScreenEmployeeRegister} />
            <PropsRoute path="/Login" component={ScreenLogin} />
            <PropsRoute path="/integracaoexpense" component={ScreenIntegracaoexpense} />
          </Switch>
        </div>

        <FooterComponent />
      </BrowserRouter>
    )
  }
}

export const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);

  console.log('top')
  window.scrollTo(0, 0);

  return (
    React.createElement(component, finalProps)
  );
}

export const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}