import React , { Component } from 'react'
// import { NavLink } from 'react-router-dom'
import { flashAlert} from '../UI/FlashAlert'
import ContactUsForm from '../ContactUsForm'
import PopupContent from '../UI/PopupContent'
import './style.css'

export default class ContactUs extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Quem Somos",
            form: false,
            typeForm: null
        }
    }

    openForm(type) {
        this.setState({
            form: !this.state.form,
            typeForm: type
        })
    }

    openAlert() {
        flashAlert()
    }

    render() {
        return(
            <main className="contact-us">
                {this.state.form &&
                    <PopupContent tittle="Form fale conosco" closePopUp={this.openForm.bind(this)}>
                        <div>
                            <ContactUsForm type={this.state.typeForm}></ContactUsForm>
                        </div>
                    </PopupContent>
                }
                
                <section className="div-1">
                    <div className="banner">
                        <h1>Quer falar com a gente?</h1>

                        <p>
                            Entre em contato!
                        </p>
                    </div>
                </section>
                <section className="div-2">
                    <div className="row">
                        <div className="col" onClick={this.openForm.bind(this, 'Bussines')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-1.jpg" alt=""/>
                            </div>
                            <h2>
                                Assessoria<br/>
                                de imprensa 
                            </h2>
                        </div>
                        <div className="col" onClick={this.openForm.bind(this, 'Company')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-2.jpg" alt=""/>
                            </div>
                            <h2>
                                Empresa
                            </h2>
                        </div>
                        <div className="col" onClick={this.openForm.bind(this, 'Users')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-3.jpg" alt=""/>
                            </div>
                            <h2>
                                Passageiros
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" onClick={this.openForm.bind(this, 'Managers')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-4.jpg" alt=""/>
                            </div>
                            <h2>
                                Gestores
                            </h2>
                        </div>
                        <div className="col" onClick={this.openForm.bind(this, 'Delivery')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-5.jpg" alt=""/>
                            </div>
                            <h2>
                                Entregadores
                            </h2>
                        </div>
                        <div className="col" onClick={this.openForm.bind(this, 'Drivers')}>
                            <div className="icon">
                                <img src="/assets/img/contact-us/ico-6.jpg" alt=""/>
                            </div>
                            <h2>
                                Taxistas e<br/>
                                Motoristas
                            </h2>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}