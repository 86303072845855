export function variantCreditCard(card) {
    const numeroCartao = card
    let result = 'other'

    /* Regex das bandeiras dos cartões */
    const masterCardRegex = /^5[1-5]/;
    const visaRegex = /^4/;
    const amexRegex = /^3[47]/;
    const discoverRegex = /^6011|622|64|65/;
    const dinersClubRegex = /^300|301|302|303|304|305|36|38/;
    const enrouteRegex = /^2014|2149/;
    const eloRegex = /^636368|438935|504175|451416|509048|509067|509049|509069|509050|509074|509068|509040|509045|509051|509046|509066|509047|509042|509052|509043|509064|509040|36297|5067|4576|4011|/;

    if (masterCardRegex.test(numeroCartao)) {
        result = 'mastercard'
    } else if (visaRegex.test(numeroCartao)) {
        result = 'visa'
    } else if (amexRegex.test(numeroCartao)) {
        result = 'amex'
    } else if (discoverRegex.test(numeroCartao)) {
        result = 'discover'
    } else if (dinersClubRegex.test(numeroCartao)) {
        result = 'dinersclub'
    } else if (enrouteRegex.test(numeroCartao)) {
        result = 'enroute'
    } else if (eloRegex.test(numeroCartao)) {
        result = 'elo'
    } else {
        result = 'other';
    }

    return result
}