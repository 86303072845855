import React, { Component } from 'react'
// import { render, unmountComponentAtNode } from 'react-dom'
// import { Close } from '@material-ui/icons'
import Select from '../UI/Select/Select'
import { InputTextVal, TextArea } from '../UI/Input/Input'
import { validateEmail } from '../../services/CpfValidation'
import Button from '../UI/Button/Button'
import axios from 'axios'
import './style.css'

export default class PopupContent extends Component {
    constructor() {
        super()

        this.state = {
            title: null,
            types: [
                {
                    name: 'Assessoria de Imprensa',
                    value: 1
                },
                {
                    name: 'Empresa',
                    value: 2
                },
                {
                    name: 'Passageiros',
                    value: 3
                },
                {
                    name: 'Gestores',
                    value: 4
                },
                {
                    name: 'Entregadoes',
                    value: 5
                },
                {
                    name: 'Taxistas e Motoristas',
                    value: 6
                }
            ],
            selectedType: null,
            email: null,
            subject: null,
            message: null
        }
    }

    static defaultProps = {
        willUnmount: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    }

    close() {
        this.props.closePopUp()
    }
    
    componentDidMount() {
        let propsTitle = null
        let typeContact = null

        switch (this.props.type) {
            case 'Bussines':
                propsTitle = "Assessoria de Imprensa"
                typeContact = 1
                break
            case 'Company':
                propsTitle = "Empresa"
                typeContact = 2
                break
            case 'Users':
                propsTitle = "Passageiros"
                typeContact = 3
                break
            case 'Managers':
                propsTitle = "Gestores"
                typeContact = 4
                break
            case 'Delivery':
                propsTitle = "Entregadoes"
                typeContact = 5
                break
            case 'Drivers':
                propsTitle = "Taxistas e Motoristas"
                typeContact = 6
                break
            default:
                break;
        }

        this.setState({
            title: propsTitle,
            selectedType: typeContact
        })
    }

    handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox'

        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }
    
    setError = (type, valid) => {
        console.log(type, valid)
        this.setState({
            [type]: valid
        })
    }

    setType(val) {
        this.setState({
            selectedType: val
        })
    }

    blurEmail(val) {
		const valLowerCase = val.toLowerCase()
		let validation = ''

		if (validateEmail(valLowerCase)) {
			validation = 'valid'
		} else {
			validation = 'invalid'
		}

		this.setState({
			email: valLowerCase,
			validEmail: {
				type: validation
			}
		})
	}

    setSubject(val) {
        this.setState({
            subject: val
        })
    }

    setMessage(val) {
        this.setState({
            message: val
        })
    }

    sendContact(e) {
        e.preventDefault()

        console.log(this.state)

        var form = new FormData();
	    form.append("from", this.state.email);
			
        form.append("emailBody", this.state.message);
        form.append("substitution[ASSUNTO]", this.state.subject);
        form.append("substitution[EMAIL]", this.state.email);
        form.append("substitution[DESCRICAO]", this.state.message);
        form.append("template_id", '78f5c9bd-da0c-4078-9ff6-ff69e179b1ce');

        switch(this.state.selectedType) {
            case 1:
                form.append("to", "comercial@wappa.com.br");
                form.append("addCc", "marketing@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Assesoria de Imprensa");
                break;
            case 2:
                form.append("to", "comercial@wappa.com.br");
                form.append("addCc", "marketing@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Lead");
                break;
            case 3:
                // form.append("substitution[IDENTIFICACAO]", contato.login);
                form.append("to", "atendimento@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Passageiro");
                break;
            case 4:
                form.append("to", "atendimento@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Gestores");
                break;
            case 5:
                form.append("to", "atendimento@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Entregadores");
                break;
            case 6:
                form.append("to", "atendimento@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Taxistas e Motoristas");
                break;
            default:
                form.append("to", "atendimento@wappa.com.br");

                // form.append("to", "vinicius.benzi@wappa.com.br");
                form.append("subject", "Outros");
                break;
        }

	    axios({
            method: 'post',
            url: 'https://email-core.wappa.com.br/email',
            data: form,
            headers: {'Content-Type': 'multipart/form-data' },
            }).then(resp => {
                //handle success
                console.log(resp);
            }).catch(error => {
                //handle error
                console.log(error.response);
                this.setState({
                    sendClienteContactSuccess: true
                })
            });
    }

    render() {
        return(
            <div className="form-contact">
                <h1>{this.state.title}</h1>
                
                {this.state.sendClienteContactSuccess ? 
                    <p>
                        Obrigado por enviar sua mensagem <br/>
                        Em breve nossa equipe entrará em contato.
                    </p>
                    :
                    <form action="contactUs">
                        <div className="row">
                            <Select
                                label="Tipo"
                                onChange={this.setType.bind(this)}
                                value={this.state.selectedType}
                                options={this.state.types}>
                            </Select>
                        </div>
                        <div className="row">
                            <InputTextVal
                                type="email"
                                autoComplete={'off'}
                                name="email"
                                onChange={this.handleChange}
                                value={this.state.email}
                                error={this.state.emailError}
                                setError={this.setError.bind(this, 'emailError')}
                                text="E-mail"
                            ></InputTextVal>
                        </div>
                        <div className="row">
                            <InputTextVal
                                type="text"
                                autoComplete={'off'}
                                name="subject"
                                onChange={this.handleChange}
                                value={this.state.subject}
                                text="Assunto"
                            ></InputTextVal>
                        </div>
                        <div className="row">
                            <TextArea text={'Menssagem'}
                                onChange={this.setMessage.bind(this)}
                            ></TextArea>
                        </div>
                        <div className="row">
                            <Button
                                onClick={this.sendContact.bind(this)}
                                disabled={
                                    !this.state.selectedType ||
                                    !this.state.email ||
                                    !this.state.subject ||
                                    !this.state.message
                                }
                                text="Enviar"
                            ></Button>
                        </div>
                    </form>
                }
            </div>
        )
    }
}