function getEnv() {
	const lh = window.location.host;
  let env = '';
  
  const env3 = lh.includes("stg") || lh.includes("localhost")
  const env4 = lh.includes("qa")
  let envWappaProxy = 'https://wpx.wappa.com.br/api/'
  let envOnboardingClient = 'https://express.api.wappa.com.br/api/1.0/express-company/'
  let envPreCadastro = 'https://appcolaborador.wappa.com.br/api/pre-cadastro/'
  
  if (env3) {
    env = ''
    envWappaProxy = 'https://wpx.wappa.com.br/Api/'
    envOnboardingClient = 'https://express.api.stg.wappa.in/api/1.0/express-company/'
    envPreCadastro = 'https://hm-appcolaborador.wappa.com.br/api/pre-cadastro/'
  } else if (env4) {
    envOnboardingClient = 'https://express.api.qa.wappa.in/api/1.0/express-company/'
    envPreCadastro = 'https://qa-appcolaborador.wappa.com.br/api/pre-cadastro/'
  }

  return env = {
    appTaxista: env,
    envWappaProxy: envWappaProxy,
    envOnboardingClient: envOnboardingClient,
    envPreCadastro: envPreCadastro
  }
}

const env = getEnv();

export const WAPPA_PROXY = env.envWappaProxy
export const ONBOARDING_API = env.envOnboardingClient
export const PRE_CADASTRO = env.envPreCadastro