import React, { Component } from 'react'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { validarCNPJ, validateEmail } from '../../../services/CpfValidation'
import { inputMask } from '../../../services/inputMasks'
import { variantCreditCard } from '../../../services/variantCreditCard'
import './style.css'

export class InputText extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched',
            type: '',
            mask: ''
        }
    }

    componentDidMount() {
        if(this.props.value) {
            this.setState({
                focus: 'focus'
            });
        }

        if(this.props.mask === 'phone') {
            this.masPhone(this.props.value)
        }

        this.setState({
            type: this.props.type
        });
    }

    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur(e) {
        const inputValue = e.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    onChange(e) {
        let inputValue = e.target.value;

        if(this.props.mask === 'phone') {
            this.masPhone(inputValue)
        } else {
            this.setState({
                value: inputValue
            });
        }
        
        this.props.onChange(inputValue);
    }

    masPhone(inputValue) {
        let inputMasc = ''

        if(inputValue.length === 0) {
            inputMasc = ``
        } else if (inputValue.length < 2) {
            inputMasc = `(${inputValue}`
        } else if (inputValue.length === 2) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}`
        } else if (inputValue.length === 3) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}.`
        } else if (inputValue.length === 4) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}.${inputValue.substring(3, 4)}`
        } else if (inputValue.length > 4 && inputValue.length < 7) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}.${inputValue.substring(3, 7)}`
        } else if (inputValue.length === 7) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}.${inputValue.substring(3, 7)}.${inputValue.substring(7, 8)}`
        } else if (inputValue.length > 7) {
            inputMasc = `(${inputValue.substring(0, 2)}) ${inputValue.substring(2, 3)}.${inputValue.substring(3, 7)}.${inputValue.substring(7, 11)}`
        }

        this.setState({
            mask: inputMasc
        })
    }

    toogleType() {
        if(this.state.type === 'password') {
            this.setState({type: 'text'})
        } else {
            this.setState({type: 'password'})
        }
    }

    render() {
        return (
            <div className={`input-text ${this.props.mask} ${this.state.focus} ${this.props.valid} ${this.props.focus} ${this.props.passNumber} -`}>
                {this.props.mask && 
                    <input value={this.state.mask} readOnly={true} className="input-masc" />
                }

                <input
                    value={this.props.value}
                    name={this.props.text}
                    type={this.state.type}
                    maxLength={this.props.maxLength}
                    max={this.props.max}
                    inputMode={this.props.inputMode}
                    pattern={this.props.pattern}
                    className={this.props.className}
                    ref={this.props.ref}
                    onChange={this.onChange.bind(this)}
                    onFocus={this.focus.bind(this)}
                    onBlur={this.blur.bind(this)}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled} />
                
                {this.props.togglePass &&
                    <div className="ico-view" onClick={this.toogleType.bind(this)}>
                        {this.state.type === 'password' && <Visibility></Visibility>}
                        {this.state.type === 'text' && <VisibilityOff></VisibilityOff>}
                    </div>
                }
                <label>{this.props.text}</label>

                {this.props.labelObs && 
                    <div className="label-obs">
                          {this.props.labelObs}
                    </div>
                }
            </div>
        )
    }
}

export class InputCode extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched'
        }
    }
    
    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur(e) {
        const inputValue = e.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    onChange(e) {
        const inputValue = e.target.value;

        this.setState({
            value: inputValue
        });

        this.props.onChange(inputValue);
    }

    render() {
        return (
            <div className={`input-code`}>
                <input
                    value={this.props.value}
                    name={this.props.text}
                    type={this.props.type}
                    maxLength={this.props.maxLength}
                    max={this.props.max}
                    inputMode={this.props.inputMode}
                    pattern={this.props.pattern}
                    className={this.props.className}
                    ref={this.props.ref}
                    onChange={this.onChange.bind(this)}
                    onFocus={this.focus.bind(this)}
                    onBlur={this.blur.bind(this)}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled} />
                <label>{this.props.text}</label>
            </div>
        )
    }
}

export class InputPhone extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched'
        }
    }
    
    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur(e) {
        const inputValue = e.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    onChange(e) {
        const inputValue = e.target.value;

        this.setState({
            value: inputValue
        });

        this.props.onChange(inputValue);
    }

    render() {
        return (
            <div className={`input-phone`}>
                <div className="country">
                    <img src="/assets/icons/br-flag.svg" alt="bandeira"/>
                    <span>+55</span>
                </div>
                <input
                    type={this.props.type}
                    name={this.props.text}
                    autoComplete={this.props.autoComplete}
                    placeholder={this.props.text}
                    onChange={this.onChange.bind(this)}/>
            </div>
        )
    }
}

export class TextArea extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched',
            type: ''
        }
    }

    componentDidMount() {
        if(this.props.value) {
            this.setState({
                focus: 'focus'
            });
        }

        this.setState({
            type: this.props.type
        });
    }

    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur(e) {
        const inputValue = e.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }
    }

    onChange(e) {
        const inputValue = e.target.value;

        this.setState({
            value: inputValue
        });

        this.props.onChange(inputValue);
    }

    toogleType() {
        if(this.state.type === 'password') {
            this.setState({type: 'text'})
        } else {
            this.setState({type: 'password'})
        }
    }

    render() {
        return (
            <div className={`input-text ${this.state.focus} ${this.props.valid} ${this.props.focus} ${this.props.passNumber} -`}>
                <textarea
                    value={this.props.value}
                    name={this.props.text}
                    type={this.state.type}
                    maxLength={this.props.maxLength}
                    max={this.props.max}
                    inputMode={this.props.inputMode}
                    pattern={this.props.pattern}
                    className={this.props.className}
                    ref={this.props.ref}
                    onChange={this.onChange.bind(this)}
                    onFocus={this.focus.bind(this)}
                    onBlur={this.blur.bind(this)}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled}>
                </textarea>
                
                {this.props.togglePass &&
                    <div className="ico-view" onClick={this.toogleType.bind(this)}>
                        {this.state.type === 'password' && <Visibility></Visibility>}
                        {this.state.type === 'text' && <VisibilityOff></VisibilityOff>}
                    </div>
                }
                <label>{this.props.text}</label>

                {this.props.labelObs && 
                    <div className="label-obs">
                          {this.props.labelObs}
                    </div>
                }
            </div>
        )
    }
}

export class InputTextVal extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched',
            type: '',
            mask: '',
            error: ''
        }
    }

    componentDidMount() {
        if(this.props.value) {
            this.setState({
                focus: 'focus'
            });
        }

        if(this.props.mask === 'phone' || this.props.mask === 'cnpj' || this.props.mask === 'zipCode') {
            this.masPhone(this.props.value)
        }
        
        this.setState({
            type: this.props.type
        });
    }

    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur = event => {
        const inputValue = event.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.value);
        }

        if(this.props.type === 'email' && !validateEmail(inputValue)) {
            this.setState({
                error: 'E-mail invalido',
                valid: 'invalid'
            })

            this.props.setError(true)
        }
    }

    onChange(event) {
        let inputValue = event.target.value;

        if (this.props.mask === 'phone') {
            if (this.mascaraInteiro(inputValue) === false) {
                this.setState({
                    value: ''
                })
            }

            if(inputValue.length < 12) {
                this.setState({
                    mask: inputMask(inputValue,'(00) 0.0000.0000', event)
                })

                this.props.onChange(event);
            }
        } else if (this.props.mask === 'cnpj') {
            if (this.mascaraInteiro(inputValue) === false) {
                this.setState({
                    value: ''
                })
            }

            const valid = validarCNPJ(inputValue)

            if(!valid) {
                this.setState({
                    error: 'CNPJ Invalido!',
                    valid: 'invalid'
                })

                this.props.setError(true)
            } else {
                this.setState({
                    error: '',
                    valid: ''
                })

                this.props.setError(false)
            }

            if(inputValue.length < 15) {
                this.setState({
                    mask: inputMask(inputValue,'00.000.000/0000-00', event)
                })

                this.props.onChange(event);
            }
        } else if (this.props.mask === 'zipCode') {
            if (this.mascaraInteiro(inputValue) === false) {
                this.setState({
                    value: ''
                })
            }

            if(inputValue.length < 12) {
                this.setState({
                    mask: inputMask(inputValue,'99.999-999', event)
                })

                this.props.onChange(event);
            }
        } else {
            this.props.onChange(event);
        }

        if(this.props.type === 'email' && validateEmail(inputValue)) {
            this.setState({
                error: '',
                valid: ''
            })

            this.props.setError(false)
        }
    }

    // MASCKS
    mascaraInteiro(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    masPhone(inputValue) {
        let inputMasc = ''

        this.setState({
            mask: inputMasc
        })
    }

    masCnpj(inputValue) {
        let inputMasc = ''

        this.setState({
            mask: inputMasc
        })
    }

    toogleType() {
        if(this.state.type === 'password') {
            this.setState({type: 'text'})
        } else {
            this.setState({type: 'password'})
        }
    }

    render() {
        return (
            <div className={`input-text ${this.props.mask} ${this.state.focus} ${this.state.valid} ${this.props.valid} ${this.props.focus} ${this.props.passNumber} -`}>
                {this.props.mask && 
                    <input value={this.state.mask} readOnly={true} className="input-masc" />
                }

                <input
                    value={this.props.value}
                    name={this.props.name}
                    type={this.state.type}
                    maxLength={this.props.maxLength}
                    max={this.props.max}
                    inputMode={this.props.inputMode}
                    pattern={this.props.pattern}
                    className={this.props.className}
                    ref={this.props.ref}
                    onChange={this.onChange.bind(this)}
                    onFocus={this.focus.bind(this)}
                    onBlur={this.blur.bind(this)}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled} />
                
                {this.props.togglePass &&
                    <div className="ico-view" onClick={this.toogleType.bind(this)}>
                        {this.state.type === 'password' && <Visibility></Visibility>}
                        {this.state.type === 'text' && <VisibilityOff></VisibilityOff>}
                    </div>
                }
                <label>{this.props.text}</label>

                {this.state.error && <div className="validate">
                    !
                    <div className="message">
                        {this.state.error}
                    </div>
                </div>}

                {this.props.error && <div className="validate">
                    !
                    <div className="message">
                        {this.props.error}
                    </div>
                </div>}

                {this.props.labelObs && 
                    <div className="label-obs">
                          {this.props.labelObs}
                    </div>
                }
            </div>
        )
    }
}

export class InputCreditCard extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            focus: 'untouched',
            type: '',
            mask: '',
            error: '',
            variant: 'other'
        }
    }

    componentDidMount() {
        if(this.props.value) {
            this.setState({
                focus: 'focus'
            });
        }

        this.masCreditCard(this.props.value)
        
        this.setState({
            type: this.props.type
        });
    }

    focus() {
        this.setState({
            focus: 'focus'
        });
    }

    blur = event => {
        const inputValue = event.target.value;

        if (inputValue.length <= 0) {
            this.setState({
                focus: 'untouched'
            });
        }

        if (this.props.onBlur) {
            this.props.onBlur(this.state.variant);
        }
    }

    onChange(event) {
        let inputValue = event.target.value;

        if (this.mascaraInteiro(inputValue) === false) {
            this.setState({
                value: ''
            })
        }

        if(inputValue.length >= 2) {
            this.setState({
                variant: variantCreditCard(inputValue)
            })
        } else {
            this.setState({
                variant: null
            })
        }

        if(inputValue.length < 20) {
            this.setState({
                mask: inputMask(inputValue,'0000-0000-0000-0000', event)
            })

            this.props.onChange(event);
        }
    }

    // MASCKS
    mascaraInteiro(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    masCreditCard(inputValue) {
        let inputMasc = ''

        this.setState({
            mask: inputMasc
        })
    }

    masCnpj(inputValue) {
        let inputMasc = ''

        this.setState({
            mask: inputMasc
        })
    }

    toogleType() {
        if(this.state.type === 'password') {
            this.setState({type: 'text'})
        } else {
            this.setState({type: 'password'})
        }
    }

    render() {
        return (
            <div className={`input-text ${this.props.mask} ${this.state.focus} ${this.state.valid} ${this.props.valid} ${this.props.focus} ${this.props.passNumber} -`}>
                {this.props.mask && 
                    <input value={this.state.mask} readOnly={true} className="input-masc" />
                }

                <input
                    value={this.props.value}
                    name={this.props.name}
                    type={this.state.type}
                    maxLength={this.props.maxLength}
                    max={this.props.max}
                    inputMode={this.props.inputMode}
                    pattern={this.props.pattern}
                    className={this.props.className}
                    ref={this.props.ref}
                    onChange={this.onChange.bind(this)}
                    onFocus={this.focus.bind(this)}
                    onBlur={this.blur.bind(this)}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled} />

                {this.state.variant && 
                    <div className="variant">
                        <img src={`./assets/icon-creditcard/${this.state.variant || 'other'}.png`} alt={this.state.variant} />
                    </div>
                }
                
                {this.props.togglePass &&
                    <div className="ico-view" onClick={this.toogleType.bind(this)}>
                        {this.state.type === 'password' && <Visibility></Visibility>}
                        {this.state.type === 'text' && <VisibilityOff></VisibilityOff>}
                    </div>
                }
                <label>{this.props.text}</label>

                {this.state.error && <div className="validate">
                    !
                    <div className="message">
                        {this.state.error}
                    </div>
                </div>}

                {this.props.error && <div className="validate">
                    !
                    <div className="message">
                        {this.props.error}
                    </div>
                </div>}

                {this.props.labelObs && 
                    <div className="label-obs">
                          {this.props.labelObs}
                    </div>
                }
            </div>
        )
    }
}