// export function inputMask(inputValue) {
//     return '(99) 9.9999.9999'
// }

export function inputMask(inputValue, masc, event) {
    let bolMasc
    const Digitato = event.keyCode
    const exp = /-|\.|\/|\(|\)| /g
    const onlyNum = inputValue.toString().replace(exp, "")
    let posicaoCampo = 0
    let inputMasc = ""
    let mascSize = onlyNum.length;

    if (Digitato !== 8) {
        for (var i = 0; i <= mascSize; i++) {
            bolMasc = ((masc.charAt(i) === "-") || (masc.charAt(i) === ".") || (masc.charAt(i) === "/"))

            bolMasc = bolMasc || ((masc.charAt(i) === "(") || (masc.charAt(i) === ")") || (masc.charAt(i) === " "))

            if (bolMasc) {
                inputMasc += masc.charAt(i)

                mascSize++
            } else {
                inputMasc += onlyNum.charAt(posicaoCampo)

                posicaoCampo++
            }
        }

        return inputMasc
    } else {
        return onlyNum
    }
}