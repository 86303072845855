import React , { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PopupContent from '../UI/PopupContent'
import './style.css'


export default class Partners extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Motoristas / Entregadores",
            popUp: false,
            titlePopUp: '',
        }
    }

    openPopUp(type, title) {
        this.setState({
            popUp: !this.state.popUp,
            titlePopUp: title,
            contenPopup: type
        })
    }

    render() {
        return(
            <main className="partners">
                {this.state.popUp &&
                    <PopupContent tittle="Form fale conosco" closePopUp={this.openPopUp.bind(this, 'docs')}>
                        <div>
                            {this.state.contenPopup === 'taxi-docs' && 
                                <div className="content-list">
                                    <h1>Documentos {this.state.titlePopUp}</h1>

                                    <ul>
                                        <li>Documento do veículo</li>
                                        <li>Comprovante de residência</li>
                                        <li>Comprovante bancário em seu nome</li>
                                        <li>CNH</li>
                                        <li>Condutax/Ciat/Permissão</li>
                                        <li>Alvára/Certificado de vistoria</li>
                                    </ul>
                                </div>
                            }

                            {this.state.contenPopup === 'drive-docs' && 
                                <div className="content-list">
                                    <h1>Documentos {this.state.titlePopUp}</h1>

                                    <ul>
                                        <li>Documento do veículo</li>
                                        <li>Comprovante bancário em seu nome</li>
                                        <li>Comprovante de residência</li>
                                        <li>CNH</li>
                                        <li>Conduapp (Motoristas de SP)</li>
                                    </ul>
                                </div>
                            }

                            {this.state.contenPopup === 'delivery-docs' && 
                                <div className="content-list">
                                    <h1>Documentos {this.state.titlePopUp}</h1>

                                    <ul>
                                        <li>CNH (Campo de observação deve constar a descrição de EAR)</li>
                                        <li>Dados Bancários ( Foto do Cartão ou Extrato Bancário, mostrando agencia e conta!</li>
                                        <li>Condumoto (Apenas na cidade de São Paulo)</li>
                                        <li>Motofrete (Demais Estados)</li>
                                        <li>Licença da Moto (Apenas na cidade de São Paulo)</li>
                                        <li>Documento da Moto (Categoria Aluguel SP, RJ, PR)</li>
                                        <li>Comprovante de residência em nome do Motofretista</li>
                                        <li>Foto de rosto (evite acessórios como óculos escuro e bonés).</li>
                                        <li>Imagem da parte traseira da moto, mostrando baú e placa.</li>
                                    </ul>
                                </div>
                            }

                            {this.state.contenPopup === 'help-payment' && 
                                <div className="content-iframe">
                                    <iframe title="Pagamentos" src="https://help.driver.wappa.com.br/help-center/sub-category/Pagamento/0001/site" frameborder="0"></iframe>
                                </div>
                            }

                            {this.state.contenPopup === 'help-register' && 
                                <div className="content-iframe">
                                    <iframe title="Cadastro" src="https://help.driver.wappa.com.br/help-center/sub-category/Cadastro/0003/site" frameborder="0"></iframe>
                                </div>
                            }

                            {this.state.contenPopup === 'help-runs' && 
                                <div className="content-iframe">
                                    <iframe title="Corridas" src="https://help.driver.wappa.com.br/help-center/sub-category/Corridas/0002/site" frameborder="0"></iframe>
                                </div>
                            }
                        </div>
                    </PopupContent>
                }

                <section className="div-1">
                    <div className="banner">
                        <h1>Atenda mais de 10 mil</h1>
                        <h2>empresas, clientes Wappa</h2>

                        <p>
                            Usuários qualificados e as corridas
                            mais lucrativas do mercado.
                            Faça parte desse time! 
                        </p>

                        <p className="label">
                            Baixe o app:
                        </p>

                        <a href="https://play.google.com/store/apps/details?id=br.com.wappa.appmobilemotorista&hl=pt_BR" target="_blank" rel="noopener noreferrer">Motorista</a>
                        <a href="https://play.google.com/store/apps/details?id=br.com.wappa.wappaentregas&hl=pt" target="_blank" rel="noopener noreferrer">Entregador</a>
                    </div>
                </section>
                <section className="div-2">
                    <h1>Vantagens de atender com a Wappa</h1>
                    <div className="row">
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/icone-motorista-1.svg" alt="app táxi"/>
                            </div>
                            <h2>
                                Faça corridas
                                e entregas
                            </h2>
                            <p>para nossos usuários. </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/icone-motorista-2.svg" alt="app táxi"/>
                            </div>
                            <h2>
                                Fature mais! 
                            </h2>
                            <p>
                                Atenda pela Wappa, 
                                em segurança 
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/icone-motorista-3.svg" alt="app táxi"/>
                            </div>
                            <h2>
                                Seja um
                                parceiro Wappa
                            </h2>
                            <p>
                                em poucos passos! 
                            </p>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/icone-motorista-4.svg" alt="app táxi"/>
                            </div>
                            <h2>
                                Pagamentos
                            </h2>
                            <p>
                                Receba suas corridas
                                em até 3 dias úteis.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="div-3">
                    <div className="foto">
                        <img src="/assets/img/driver-delivery/foto-4.jpg" alt="app táxi"/>
                    </div>
                    <div className="text">
                        <h1>Realize o seu cadastro</h1>

                        <p>
                            Se precisar, chama a gente! Vamos te ajudar :) 
                        </p>

                        <a href="https://play.google.com/store/apps/details?id=br.com.wappa.appmobilemotorista&hl=pt_BR" target="_blank" rel="noopener noreferrer">Quero ser motorista</a>
                        <a href="https://play.google.com/store/apps/details?id=br.com.wappa.wappaentregas&hl=pt_BR" target="_blank" rel="noopener noreferrer">Quero ser entregador</a>
                    </div>
                </section>
                <section className="div-4">
                    <h1>
                        Documentos que você precisa
                        para atender pela Wappa:
                    </h1>
                    <div className="row">
                        <div className="col" onClick={this.openPopUp.bind(this, 'taxi-docs', 'Taxista')}>
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/ico-5.jpg" alt="app táxi"/>
                            </div>
                            <h2>
                                Taxista
                            </h2>
                        </div>
                        <div className="col" onClick={this.openPopUp.bind(this, 'drive-docs', 'Motorista')}>
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/ico-6.jpg" alt="app táxi"/>
                            </div>
                            <h2>
                                Motorista
                            </h2>
                        </div>
                        <div className="col" onClick={this.openPopUp.bind(this, 'delivery-docs', 'Motoboy')}>
                            <div className="icon">
                                <img src="/assets/img/driver-delivery/ico-7.jpg" alt="app táxi"/>
                            </div>
                            <h2>
                                Motoboy
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="div-5">
                    <h1>Já é um parceiro Wappa?</h1>
                    <p>Em que podemos te ajudar?</p>
                    <div className="row">
                        <div className="col" onClick={this.openPopUp.bind(this, 'help-payment', '')}>PAGAMENTOS</div>
                        <div className="col" onClick={this.openPopUp.bind(this, 'help-register', '')}>CADASTRO</div>
                        <div className="col" onClick={this.openPopUp.bind(this, 'help-runs', '')}>CORRIDAS</div>
                    </div>
                    <p className="help">
                        Ainda tem dúvidas? <NavLink className="Tabs logo" to={`/fale-conosco`} >Entre em contato conosco.</NavLink>
                    </p>
                </section>
            </main>
        )
    }
}