import React, { Component } from 'react'
// import { render, unmountComponentAtNode } from 'react-dom'
import { Close } from '@material-ui/icons'
import './style.css'

export default class PopupContent extends Component {
    static defaultProps = {
        willUnmount: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    }

    close() {
        this.props.closePopUp()
    }

    render() {
        return(
            <div>
                <div className="popup-content-overlay" onClick={this.close.bind(this)}>
                </div>
                <div className="popup-content">
                    <Close className="close" onClick={this.close.bind(this)}></Close>
                    <div className="content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}