import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

export default class Home extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Principal"
        }
    }

    componentDidMount() {
        const vehiclesDiv = document.getElementsByClassName("scroll-vehicles")

        vehiclesDiv[0].scrollLeft = 500
    }

    render() {
        return(
            <main className="home">
                <section className="div-1">
                    <div className="banner">
                        <h1>Mobilidade & Logística</h1>
                        <h2>em uma mesma plataforma</h2>

                        <p>
                            Conectando você e seu negócio com seu
                            cliente de maneira fácil e eficiente.
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Experimente</NavLink>
                    </div>
                </section>
                <section className="div-2">
                    <h1>
                        Levamos tudo para você
                        e você para onde quiser
                    </h1>

                    <div className="scroll-vehicles">
                        <div className="vehicle-list">
                            <div className="vehicle escpecial"></div>
                            <div className="vehicle taxi"></div>
                            <div className="vehicle motocycle"></div>
                            <div className="vehicle taxi-blue"></div>
                            <div className="vehicle carro-black"></div>
                            <div className="vehicle drone"></div>
                        </div>
                    </div>
                </section>
                <section className="div-3">
                    <div className="col">
                        <h1>Mobilidade</h1>

                        <p>
                            Táxi e carro particular no mesmo aplicativo e você pode controlar tudo pela plataforma
                            Wappa. Seu time e você, se locomovendo em segurança.
                        </p>

                        <NavLink className="Tabs" to={`/mobilidade`}>Saiba mais</NavLink>
                    </div>
                    <div className="col">
                        <h1>Logística</h1>

                        <p>
                            Serviços de entregas para tudo que você precisa. Entrega expressa? Chame um Motoboy.
                            Entregas maiores? Chame um táxi ou carro particular. 
                        </p>

                        <NavLink className="Tabs" to={`/logistica`}>Saiba mais</NavLink>
                    </div>
                </section>
                <section className="div-4">
                    <h1>
                        Conheça opção ideal para você <br/>
                        ou seja um parceiro da Wappa!
                    </h1>

                    <div className="row">
                        <div className="col">
                            <div className="foto">
                                <div className="img"></div>
                                <div className="border"></div>
                            </div>

                            <h2>Para sua empresa</h2>

                            <p>
                                Corridas e Entregas,
                                Táxi e carro particular.
                                Controle tudo pela
                                plataforma Wappa.
                            </p>

                            <NavLink className="Tabs" to={`/para-empresas`}>Saiba mais</NavLink>
                        </div>
                        <div className="col">
                            <div className="foto">
                                <div className="img"></div>
                                <div className="border"></div>
                            </div>

                            <h2>Para você</h2>

                            <p>
                                Baixe nosso aplicativo
                                e utilize nossos serviços
                                na sua conta pessoal.
                            </p>

                            <NavLink className="Tabs" to={`/para-voce`}>Saiba mais</NavLink>
                        </div>
                        <div className="col">
                            <div className="foto">
                                <div className="img"></div>
                                <div className="border"></div>
                            </div>

                            <h2>Motoristas e Entregadores</h2>

                            <p>
                                Faça corridas e entregas
                                e fature ainda mais!
                            </p>

                            <NavLink className="Tabs" to={`/motoristas-entregadores`}>Saiba mais</NavLink>
                        </div>
                    </div>
                    
                </section>
                <section className="div-5">
                    <h1>Quem se locomove e entrega com a Wappa.</h1>

                    <div className="cases">
                        <div>
                            <div className="logo sul-america"></div>
                            <div className="logo globo"></div>
                            <div className="logo citibank"></div>
                            <div className="logo itau"></div>
                            <div className="logo vivo"></div>
                            <div className="logo amil"></div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}