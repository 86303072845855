import React , { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

export default class Logistics extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Logística"
        }
    }

    render() {
        return(
            <main className="logistics">
                <section className="div-1">
                    <div className="banner">
                        <h1>Levamos o que</h1>
                        <h2>você precisar</h2>

                        <p>
                            Para pequenas, médias e grandes
                            empresas. A logística da Wappa,
                            é o que você precisa. 
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Experimente</NavLink>
                    </div>
                </section>
                <section className="div-2">
                    <div className="foto">
                        <img src="/assets/img/logistics/Imagem-4.jpg" alt="app táxi"/>
                    </div>
                    <div className="text">
                        <h1>Escritórios</h1>

                        <p>
                            Gerencie o fluxo de entregas através de
                            relatórios completos, histórico de pedidos
                            e recibos, sempre que precisar.
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Quero experimentar</NavLink>
                    </div>
                </section>
                <section className="div-3">
                    <div className="text">
                        <h1>E-commerce</h1>

                        <p>
                            Com a plataforma Wappa, você acompanha
                            sua entrega em tempo real. Conte com a
                            Wappa para potencializar o seu negócio. 
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Quero saber mais</NavLink>
                    </div>
                    <div className="foto">
                        <img src="/assets/img/logistics/Imagem-5.jpg" alt="app táxi"/>
                    </div>
                </section>
                <section className="div-4">
                    <div className="foto">
                        <img src="/assets/img/logistics/Imagem-3.jpg" alt="app táxi"/>
                    </div>
                    <div className="text">
                        <h1>Utilize uma plataforma</h1>
                        <h3>completa para gestão</h3>

                        <p>
                            Uma plataforma completa para você controlar e visualizar o fluxo de mobilidade,
                            integrar com sistemas Financeiros e folhas de pagamento e controlar o orçamento
                            por centro de custo e colaboradores.
                        </p>
                    </div>
                </section>
                <section className="div-5">
                    <h1>Tudo o que seu negócio precisa</h1>
                    <div className="row">
                        <div className="col">
                            <div className="ico">
                                <img src="/assets/img/logistics/ico-moto.jpg" alt="moto"/>
                            </div>
                            <h2>Entregas expressas e pacotes menores</h2>
                            <p>solicite um entregador motoboy</p>
                        </div>
                        <div className="col">
                            <div className="ico">
                                <img src="/assets/img/logistics/ico-car.jpg" alt="moto"/>
                            </div>
                            <h2>Entregas ou retirada de  pacotes maiores</h2>
                            <p>solicite um motorista</p>
                        </div>
                    </div>
                </section>
                <section className="div-6">
                    <h1>Só na Wappa você tem Táxi, Carro e Moto</h1>
                    <p>
                        tudo de acordo com a sua necessidade!
                    </p>
                </section>
            </main>
        )
    }
}