import React , { Component } from 'react'
import './style.css'

export default class Personal extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Para Você"
        }
    }

    openApp(store) {
        let storeUrl = null
        switch (store) {
            case 'appstore':
                storeUrl = 'https://apps.apple.com/br/app/wappa-t%C3%A1xi-com-seguran%C3%A7a-e-praticidade/id385335472'
                break;
            case 'googleplay':
                storeUrl = 'https://play.google.com/store/apps/details?id=br.com.wappa.appmobilecolaborador&hl=pt_BR'
                break;
            default:
                break;
        }

        const appWindow = window.open(storeUrl)

        setTimeout(() => {
            if (appWindow) {
                appWindow.location = storeUrl
            }
        },1000);
    }

    render() {
        return(
            <main className="personal">
                <section className="div-1">
                    <div className="banner">
                        <h1>Chegue aonde quiser</h1>

                        <p>
                            Você pode solicitar os
                            serviços Wappa, através
                            da sua conta pessoal. 
                        </p>

                        <div className="app-stores">
                        
                            <div onClick={this.openApp.bind(this, 'appstore')} className="apple-store">Apple store</div>


                            <div onClick={this.openApp.bind(this, 'googleplay')} className="google-play">Google Play</div>
                        </div>                      
                    </div>
                </section>
                <section className="div-2">
                    <div className="foto">
                        <div className="border">
                            <img src="/assets/img/for-you/foto.jpg" alt="app táxi"/>
                        </div>
                    </div>
                    <div className="text">
                        <h1>Como fazer?</h1>

                        <ul>
                            <li>Vá até a sua loja de aplicativos e procure “Wappa”;</li>
                            <li>Baixe ou atualize o seu aplicativo;</li>
                            <li>Faça o cadastro com seu endereço de e-mail pessoal;</li>
                            <li>Pronto! Você já pode solicitar seu carro via Wappa; Aproveite!</li>
                        </ul>
                        <a href="https://apps.apple.com/br/app/wappa-t%C3%A1xi-com-seguran%C3%A7a-e-praticidade/id385335472"
                            target="blank" rel="noopener noreferrer" className="apple-store">
                                <h3>Baixar o aplicativo IOS</h3>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=br.com.wappa.appmobilecolaborador&hl=pt_BR"
                            target="blank" rel="noopener noreferrer" className="google-play">
                            <h3>Baixar o aplicativo Android</h3>
                        </a>
                    </div>
                </section>
                <section className="div-3">
                    <h1>O serviço premium da Wappa, para você!</h1>
                    <div className="row">
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/for-you/ico-1.jpg" alt="star"/>
                            </div>

                            <h2>
                                Motoristas<br/>
                                qualificados
                            </h2>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <img src="/assets/img/for-you/ico-2.jpg" alt="car"/>
                            </div>

                            <h2>
                                Viaje em<br/>
                                segurança
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="div-4">
                    <h1>Baixe o app</h1>
                    <div className="app-stores">
                        <div onClick={this.openApp.bind(this, 'appstore')} className="apple-store">Apple store</div>


                        <div onClick={this.openApp.bind(this, 'googleplay')} className="google-play">Google Play</div>
                    </div> 
                </section>
            </main>
        )
    }
}