import React, { Component } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { Close } from '@material-ui/icons'
import './style.css'

export default class ReactFlashAlert extends Component {
    static defaultProps = {
        message: 'Alerta',
        timer: 3000,
        willUnmount: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    }

    handleClickButton = func => {
        if (func) func()
        this.close()
    }

    close = () => {
        removeBodyClass()
        animateOutFlashAlert()
    }

    componentDidMount = () => {
        const target = document.getElementById('react-flash-alert')

        setTimeout(() => {
            if (target) {
                this.close()
            }
        }, this.props.timer)
    }

    componentWillUnmount = () => {
        this.props.willUnmount()
    }

    render() {
        const { message, func } = this.props

        return (
            <div className="react-flash-alert-overlay">
                <div className="react-flash-alert">
                    <div className="content">
                        {message}
                    </div>
                    <div className="close" onClick={() => this.handleClickButton(func)}>
                        <Close></Close>
                    </div>
                </div>
            </div>
        )
    }
}

function animateOutFlashAlert() {
    console.log('animate out')
    const divTarget = document.getElementById('react-flash-alert')
    if (divTarget) divTarget.classList.add('out-flash')

    setTimeout(() => {
        removeElementFlashAlert()
    }, 500)
}

function createElementFlashAlert(properties) {
    let divTarget = document.createElement('div')
    divTarget.id = 'react-flash-alert'
    document.body.appendChild(divTarget)
    render(<ReactFlashAlert {...properties} ></ReactFlashAlert>, divTarget)
}

function removeElementFlashAlert() {
    const target = document.getElementById('react-flash-alert')
    if (target) {
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
    }
}

function addBodyClass() {
    document.body.classList.add('react-flash-alert-body')
}

function removeBodyClass() {
    console.log('remove')
    document.body.classList.remove('react-flash-alert-body')
}

export function flashAlert(properties) {
    addBodyClass()
    createElementFlashAlert(properties)
}