import React, { Component } from 'react'
import Button from '../../UI/Button/Button'
import { InputTextVal } from '../../UI/Input/Input'
import axios from 'axios'

export default class ClientPremiumContact extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            company: '',
            numEmployees: '',
            email: '',
            emailError: '',
            phone: '',
            phoneError: '',
            sendClienteContactSuccess: false
        }
    }

    handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox'

        this.setState({
            [event.target.name]: isCheckbox
                ? event.target.checked
                : event.target.value
        })
    }

    handleSelectChange = (name, val) => {
        this.setState({
            [name]: val
        })
    }

    setError = (type, valid) => {
        console.log(type, valid)
        this.setState({
            [type]: valid
        })
    }

    sendClienteContact = event => {
        event.preventDefault()

        let form = new FormData();
        form.append("from", this.state.email);
        
        form.append("emailBody", 'Lead empresa premium');
        form.append("substitution[EMPRESA]", this.state.company);
        form.append("substitution[CONTATO]", this.state.name);
        form.append("substitution[EMAIL]", this.state.email);
        form.append("substitution[DESCRICAO]", '');
        form.append("substitution[TELEFONE]", this.state.phone);
        form.append("substitution[NUMCORRIDAS]", '' || 'não informado');
        form.append("substitution[NUMCOLABORADORES]", this.state.numEmployees || 'não informado');
        form.append("template_id", '95f9aed3-aa11-4a53-8218-b341b33230da');

        // form.append("to", "vinicius.benzi@wappa.com.br");
        // form.append("addCc", "vinicius.benzi@gmail.com");

        form.append("to", "comercial@wappa.com.br");
        form.append("addCc", "marketing@wappa.com.br");
        form.append("addCco", "vinicius.benzi@wappa.com.br");

        form.append("substitution[ASSUNTO]", "Empresa Premium");
        form.append("subject", "LEAD - Empresa Premium");

        axios({
            method: 'post',
            url: 'https://email-core.wappa.com.br/email',
            data: form,
            headers: {'Content-Type': 'multipart/form-data' },
            }).then(resp => {
                //handle success
                console.log(resp);
            }).catch(error => {
                //handle error
                console.log(error.response);
                this.setState({
                    sendClienteContactSuccess: true
                })

                window.gtag('event', 'conversion', { "on": "visible", "vars": { "event_name": "conversion_premium", "send_to": ["AW-914084232/ElL3CIe7nesBEIij77MD"] } })
            });
    }

    render() {
        return(
            <>
                {!this.state.sendClienteContactSuccess ?
                    <>
                        <h1>Mobilidade e Logística na mesma plataforma!</h1>

                        <p>
                            Controle todo fluxo de Entregas e Corridas da sua empresa, de uma maneira muito fácil e rápida.
                        </p>
        
                        <p>
                            Para começar, por favor, preencha os dados abaixo:
                        </p>

                        <form action="clientInfo" autoComplete="none" className="payment-form">
                            <div className="row">
                                <InputTextVal
                                    type="text"
                                    autoComplete={'off'}
                                    onChange={this.handleChange}
                                    value={this.state.name || ''}
                                    focus={this.state.name && 'focus'}
                                    name="name"
                                    text="Nome"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal
                                    type="text"
                                    autoComplete={'off'}
                                    onChange={this.handleChange}
                                    value={this.state.company || ''}
                                    focus={this.state.company && 'focus'}
                                    name="company"
                                    text="Nome da empresa"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal
                                    type="number"
                                    autoComplete={'off'}
                                    onChange={this.handleChange}
                                    value={this.state.numEmployees || ''}
                                    focus={this.state.numEmployees && 'focus'}
                                    name="numEmployees"
                                    text="Quantidade de funcionários"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal
                                    type="email"
                                    autoComplete={'off'}
                                    name="email"
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    error={this.state.emailError}
                                    setError={this.setError.bind(this, 'emailError')}
                                    text="E-mail"
                                ></InputTextVal>
                            </div>
                            <div className="row">
                                <InputTextVal type="number"
                                    text="Celular"
                                    autoComplete="off"
                                    mask="phone"
                                    name="phone"
                                    max="99999999999"
                                    maxLength={10}
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    error={this.state.phoneError}
                                    setError={this.setError.bind(this, 'phoneError')}
                                ></InputTextVal>
                            </div>
                            <div className="options">
                                <Button
                                    onClick={this.sendClienteContact.bind(this)}
                                    disabled={
                                        !this.state.name ||
                                        !this.state.company ||
                                        !this.state.numEmployees ||
                                        !this.state.email ||
                                        this.state.emailError ||
                                        !this.state.phone ||
                                        this.state.phoneError
                                    }
                                    text="Enviar"
                                ></Button>
                            </div>
                        </form>
                        
                    </>
                    :
                    <>
                        <h1>Obrigado por enviar sua mensagem.</h1>

                        <p>
                            Nossa equipe entrará em contato com você o mais rápido possível =)
                        </p>
                    </>
                }
            </>
        )
    }
}