export function CpfValidation(strCPF) {
    const cpfCleaning = strCPF.replace(/[^\d]+/g, '');

    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpfCleaning.length < 11)
        return false;

    for (i = 0; i < cpfCleaning.length - 1; i++)
        if (cpfCleaning.charAt(i) !== cpfCleaning.charAt(i + 1)) {
            digitos_iguais = 0;
            break;
        }

    if (!digitos_iguais) {
        numeros = cpfCleaning.substring(0, 9);
        digitos = cpfCleaning.substring(9);
        soma = 0;

        for (i = 10; i > 1; i--)
            soma += numeros.charAt(10 - i) * i;

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        const resulString = resultado.toString();

        if (resulString !== digitos.charAt(0)) {
            return false;
        }

        numeros = cpfCleaning.substring(0, 10);

        soma = 0;

        for (i = 11; i > 1; i--)
            soma += numeros.charAt(11 - i) * i;

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            
        return true;
    }
    else {
        return false;
    }
}

export function validarCNPJ (val) {
    const cnpj = val.replace(/[^\d]+/g, '');
    // let valid = true

    if (cnpj === '') {
        return false
    }

    if (cnpj.length !== 14)  {
        return false
    }

    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999") {
            return false
        }

    
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    var chat0 = digitos.charAt(0);

    if (resultado !== parseInt(chat0)) {
        return false
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var l = tamanho; l >= 1; l--) {
        soma += numeros.charAt(tamanho - l) * pos--;
        if (pos < 2)
            pos = 9;
    }

    console.log({test: 'valid', name: val})
    return true
}

export function cleanMask(strCPF) {
    const noMaskCPF = strCPF.replace(/[^\w\s]/gi, '');

    const numCPF = parseInt(noMaskCPF);

    return numCPF;
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email);
}