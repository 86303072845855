const Integracaoexpense = () => {
    const lh = window.location.host

    const env3 = lh.includes("stg") || lh.includes("localhost")
    const env4 = lh.includes("qa")
    let envIntegracaoExpense = 'https://expense.api.wappa.com.br/'
  
    if (env3) {
        envIntegracaoExpense = "https://expense.api.stg.wappa.in/"
    } else if (env4) {
        envIntegracaoExpense = "https://expense.api.qa.wappa.in/"
    }

    window.location.href = envIntegracaoExpense
};

export default Integracaoexpense;