import React , { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

export default class AboutUs extends Component {
    constructor() {
        super()
        
        this.state = {
            title: "Quem Somos"
        }
    }

    render() {
        return(
            <main className="about-us">
                <section className="div-1">
                    <div className="banner">
                        <h1>Conectando pessoas</h1>
                        <h2>através da Mobilidade</h2>

                        <p>
                            Economia • Segurança • Agilidade
                        </p>

                        <NavLink className="Tabs" to={`/para-empresas`}>Experimente</NavLink>
                    </div>
                </section>
                <section className="div-2">
                    <h1>Sobre a Wappa</h1>

                    <div className="row">
                        <div className="text">
                            <h2>Líder no mercado corporativo</h2>

                            <p>
                                Com 18 anos de mercado, a Wappa está
                                presente em 1.500 cidades de todos os
                                estados do Brasil. São 210 mil motoristas
                                cadastrados, 10 mil clientes, de PMEs a
                                corporações, e 1 milhão de usuários. 
                            </p>
                        </div>
                        <div className="foto">
                            <div className="border">
                                <img src="/assets/img/about-us/foto-1.jpg" alt="app táxi"/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="foto">
                            <div className="border">
                                <img src="/assets/img/about-us/foto-2.jpg" alt="app táxi"/>
                            </div>
                        </div>
                        <div className="text">
                            <h2>Parceiros qualificados</h2>

                            <p>
                                A Wappa opera somente com Taxistas, Motoristas
                                e Entregadores regularizados. Prestamos atendimento
                                diferenciado ao mercado corporativo e ao público em geral. 
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="text">
                            <h2>Benefícios para empresas</h2>

                            <p>
                                Para o segmento empresarial, a Wappa disponibiliza uma plataforma completa que
                                visa entregar eficiência na redução de custos e otimização de processos, diferenciais que
                                fazem da companhia uma referência no assunto no Brasil. 
                            </p>
                        </div>
                        <div className="foto">
                            <div className="border">
                                <img src="/assets/img/about-us/foto-3.jpg" alt="app táxi"/>
                            </div>
                        </div>
                    </div>                    
                </section>
            </main>
        )
    }
}